<template>
  <div class="">
    <el-row :gutter="10" style="min-height: 60px;" v-loading="docConfig.isLoadData">
      <template v-if="docConfig.data && docConfig.data.length > 0">
        <template v-for="document in docConfig.data">
          <el-col :span="8">
            <a :href="`/techdocs/view/${document.docid}`" target="_blank">
              <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-b10">
                <el-image
                  style="width: 100%;height: 200px;"
                  :src="document.doccoverImgurl" fit="cover"
                ></el-image>
                <div class="" style="padding: 0 8px 8px;">
                  <div class="sctp-ellipsis sctp-mar-tb5">{{ document.docname }}</div>
                  <div class="flex flex-sb fz-12 fc-info">
                    <div>
                      <span>{{ document.subTypeName }}</span>
                    </div>
                    <div>
                      <span>{{ getCity(document.cityCode) }}</span>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                    <div class="sctp-red">{{
                        document.realprice ? `¥${document.realprice}` : '免费'
                      }}
                    </div>
                    <div class="">浏览数：{{ document.hits || 0 }}</div>
                  </div>
                  <div @click.prevent="" class="flex flex-sb pad-lr5">
                    <div></div>
                    <div>
                      <el-dropdown @command="handleCommand($event, document)" size="mini">
                        <i class="el-icon-more"></i>
                        <el-dropdown-menu slot="dropdown">
                          <template v-for="item in dropdownOptions(document)">
                            <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                          </template>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </el-col>
        </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white">
      <el-pagination
        :current-page="docConfig.pageConfig.page"
        :page-size="docConfig.pageConfig.limit"
        @size-change="docConfig.pageConfig.handleSizeChange"
        @current-change="docConfig.pageConfig.handleCurrentChange"
        :page-sizes="docConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="docConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "@/apis";
import area from "@/resources/js/area";

export default {
  name: "favorite_doc",
  data() {
    return {
      docConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {page, limit} = this.docConfig.pageConfig;
          this.docConfig.isLoadData = true;
          collection.getDocument({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {retdata, count = 0} = res;
            this.docConfig.data = retdata
            this.docConfig.pageConfig.total = count;
          }).finally(() => {
            this.docConfig.isLoadData = false;
          });
        },
        pageConfig: {
          page: 1,
          limit: 10,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 10),
          handleSizeChange: (limit) => {
            this.docConfig.pageConfig.limit = limit;
            this.docConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.docConfig.pageConfig.page = page;
            this.docConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    handleCommand: (command, item) => {
      command.command(item);
    },
  },
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.docid,
                targetType: 6
              }).then(res => {
                const {retdata} = res;
                if (retdata) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.docConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    }
  },
  beforeMount() {
    this.docConfig.loadData();
  }
}
</script>

<style scoped lang="scss">
.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin: 5px 0;
  width: 100%;
}
</style>
